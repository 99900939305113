import {withTranslation} from '@wix/yoshi-flow-editor'
import {isEditor} from '../../../../../commons/selectors/environment'
import {
  getSideBySideLayoutMargins,
  isDescriptionEnabled,
  isAnyRibbonEnabled,
  getComponentConfig,
} from '../../selectors/settings'
import {getElementWidth} from '../../../../../commons/utils/dom'
import {getEvents} from '../../selectors/events'
import {isImageVisible, isMembersEnabled, isSocialShareVisible} from '../../selectors/list-settings'
import {AppProps} from '../app/interfaces'
import {connect} from '../runtime-context/connect'
import {SideBySideOwnProps, SideBySideStateProps} from './interfaces'
import {SideBySide as SideBySidePresentation} from './side-by-side'

const mapRuntime = ({state, host: {dimensions}}: AppProps): SideBySideStateProps => ({
  events: getEvents(state),
  showImage: isImageVisible(state),
  showMembers: isMembersEnabled(state),
  showRibbon: isAnyRibbonEnabled(state.component, false),
  showSocial: isSocialShareVisible(state),
  showDescription: isDescriptionEnabled(state.component),
  editor: isEditor(state),
  margins: getSideBySideLayoutMargins(getComponentConfig(state)),
  width: dimensions.width || getElementWidth(`#${state.component.id}`),
})

export const SideBySide = connect<SideBySideOwnProps, SideBySideStateProps>(mapRuntime)(
  withTranslation()(SideBySidePresentation),
)
export * from './interfaces'
