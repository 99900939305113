import _ from 'lodash'
import {updateSiteSettings} from '../../../../commons/actions/site-settings'
import {getComponentEventIds} from '../reducers/component'
import {getEventsIds, getFirstEvent} from '../selectors/events'
import {GetState, State} from '../types/state'
import {updateComponentDraft, updateComponentTexts, updateStyleParams, INJECT_COMPONENT_DRAFT} from './component'
import {reloadEvents} from './events'
import {textsSectionChanged} from './fonts-panel'
import {closeListLayoutItems, hideRibbonOnItems, openListLayoutItem, showRibbonOnItems} from './layout'
import {forceResize} from './resize'
import {settingsSectionChanged, settingsTabChanged} from './settings-panel'

export enum SettingsUpdatedActionType {
  SETTINGS = 'SETTINGS',
  SITE_SETTINGS = 'SITE_SETTINGS',
  SELECT_EVENT = 'SELECT_EVENT',
  // eslint-disable-next-line no-shadow
  INJECT_COMPONENT_DRAFT = 'INJECT_COMPONENT_DRAFT',
  RESIZE = 'RESIZE',
  EXPAND_EVENT = 'EXPAND_EVENT',
  COLLAPSE_EVENT = 'COLLAPSE_EVENT',
  DISPLAY_EVENT = 'DISPLAY_EVENT',
  HIDE_EVENT = 'HIDE_EVENT',
  NAVIGATE_TO_PAGE = 'NAVIGATE_TO_PAGE',
  NAVIGATE_TO_PAGE_NEW = 'NAVIGATE_TO_PAGE/fulfilled',
  TAB_CHANGED = 'TAB_CHANGED/fulfilled',
  SETTINGS_TAB_CHANGED = 'SETTINGS_TAB_CHANGED',
  SETTINGS_SECTION_CHANGED = 'SETTINGS_SECTION_CHANGED',
  TEXTS_SECTION_CHANGED = 'TEXTS_SECTION_CHANGED',
  SHOW_RIBBON = 'SHOW_RIBBON',
  HIDE_RIBBON = 'HIDE_RIBBON',
}

interface SettingsUpdateAction {
  type: SettingsUpdatedActionType
  [key: string]: any
}

export const updateSettings = (action: SettingsUpdateAction) => async (dispatch: Function, getState: GetState) => {
  switch (action.type) {
    case SettingsUpdatedActionType.SETTINGS:
      debouncedUpdateComponentDraft(action.type, getState, action, dispatch)
      dispatch(updateComponentTexts(action.settings.texts))
      break
    case SettingsUpdatedActionType.SITE_SETTINGS:
      dispatch(updateSiteSettings(action.settings))
      break
    case SettingsUpdatedActionType.SELECT_EVENT:
      dispatch(
        updateComponentDraft(action.type, {
          byEventId: {eventId: [action.eventId]},
        }),
      )
      break
    case SettingsUpdatedActionType.INJECT_COMPONENT_DRAFT:
      await dispatch({
        type: INJECT_COMPONENT_DRAFT,
        payload: {
          ...action.payload,
        },
      })
      dispatch(reloadEvents())
      break
    case SettingsUpdatedActionType.SHOW_RIBBON:
      dispatch(showRibbonOnItems(action.payload))
      break
    case SettingsUpdatedActionType.HIDE_RIBBON:
      dispatch(hideRibbonOnItems())
      break
    case SettingsUpdatedActionType.EXPAND_EVENT:
      {
        const event = getFirstEvent(getState())
        if (event) {
          dispatch(openListLayoutItem(event.id))
        }
      }
      break
    case SettingsUpdatedActionType.COLLAPSE_EVENT:
      dispatch(closeListLayoutItems())
      break
    case SettingsUpdatedActionType.DISPLAY_EVENT:
      {
        const events = [...getSelectedEvents(getState()), action.eventId]
        dispatch(updateComponentDraft(action.type, {byEventId: {eventId: events}}))
      }
      break
    case SettingsUpdatedActionType.HIDE_EVENT:
      {
        const events = getSelectedEvents(getState()).filter(eventId => eventId !== action.eventId)
        dispatch(updateComponentDraft(action.type, {byEventId: {eventId: events}}))
      }
      break
    case SettingsUpdatedActionType.SETTINGS_TAB_CHANGED:
      dispatch(settingsTabChanged(action.tab))
      break
    case SettingsUpdatedActionType.SETTINGS_SECTION_CHANGED:
      dispatch(settingsSectionChanged(action.id))
      break
    case SettingsUpdatedActionType.TEXTS_SECTION_CHANGED:
      dispatch(textsSectionChanged(action.id))
      break
    case SettingsUpdatedActionType.RESIZE:
      dispatch(forceResize())
      break
    default:
      break
  }
}

export const updateStyle = (params: any) => async (dispatch: Function, getState) => {
  const layoutChanging = isLayoutChanging(getState(), params)
  await dispatch(updateStyleParams(params))
  if (layoutChanging) {
    dispatch(reloadEvents())
  }
}

export const isLayoutChanging = (state: State, params: any) => {
  const {listLayout, widgetLayout, recurringFilter} = state.component.settings
  const {listLayout: listLayoutNew, widgetLayout: widgetLayoutNew, recurringFilter: recurringFilterNew} = params.numbers

  const isParamChanging = (newValue, oldValue) => newValue !== undefined && oldValue !== newValue

  return (
    isParamChanging(listLayoutNew, listLayout) ||
    isParamChanging(widgetLayoutNew, widgetLayout) ||
    isParamChanging(recurringFilterNew, recurringFilter)
  )
}

const debouncedUpdateComponentDraft = _.debounce((type: string, getState: GetState, action, dispatch: Function) => {
  const state = getState()
  const obsoleteKeys = [
    'closedRegistrationMessages',
    'messages',
    'shoutoutTemplateClicked',
    ...Object.keys(state.component.params),
  ]
  const compSettings = _.omit(action.settings, obsoleteKeys)

  dispatch(updateComponentDraft(type, {settings: compSettings}, action.reload))
}, 1000)

const getSelectedEvents = (state: State) => getComponentEventIds(state) || getEventsIds(state)
